<template>
  <div class="block-space">
    <swiper :options="swiperOption" v-if="companies.length == 0">
      <swiper-slide v-for="(item, index) in 12" :key="index">
        <v-skeleton-loader class="px-4 my-4 item-img" type="image"></v-skeleton-loader>
      </swiper-slide>
    </swiper>

    <swiper :options="swiperOption" v-else>
      <swiper-slide
        v-for="(item, index) in companies"
        :key="index"
        class="max-heigth:150px;"
      >
        <a :href="'/asociations/' + item.link" class="item-img-container my-2">
          <img
            class="item-img mx-auto"
            :title="item.name"
            v-if="item.countries.length > 0 && item.countries[0].flag != ''"
            :src="item.countries[0].flag"
            :alt="item.countries[0].name"
          />
        </a>
      </swiper-slide>
    </swiper>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        companies: [],
        swiperOption: {
          speed: 300,
          loop: true,
          centerInsufficientSlides: true,
          autoplay: {
            delay: 3000,
          },
          breakpoints: {
            1440: {
              slidesPerView: 10,
              spaceBetween: 10,
            },
            1025: {
              slidesPerView: 8,
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 6,
              spaceBetween: 20,
            },

            576: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
            320: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
          },
        },
      }
    },
    created() {
      this.getCompanies()
    },
    methods: {
      async getCompanies() {
        axios
          .get('companies-list?type=1&active=1&limit=30')

          .then((response) => {
            this.companies = response.data
            if (this.companies.length == 0) {
              this.boilerplateActive = true
              // this.carouselEmpty = true
            }
          })
      },
    },
  }
</script>
<style lang="css">
  /* .swiper-wrapper {
    justify-content: center;
  } */
  .item-img-container {
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(1);
    transition: transform 0.5s ease-out;
  }
  .item-img-container:hover {
    transform: scale(1.15);
    transition: transform 0.5s ease-out;
  }
  .item-img {
    width: 90px;
    height: 60px;
  }
</style>
